import LoadingSpinner from "@client/components/LoadingSpinner";
import FormSpacer from "@client/components/form/helpers/FormSpacer";
import Label from "@client/components/form/helpers/Label";
import CheckBoxInput from "@client/components/form/inputs/CheckBoxInput";
import TextFieldInput from "@client/components/form/inputs/TextFieldInput";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Container,
  Grid,
  IconButton,
  Link as MuiLink,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { FormText } from "@public/cms/types";
import { theme } from "@styles/Theme";
import axios from "axios";
import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import ReactGA from "react-ga4";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  DirectorResults,
  OneManBusinessResults,
  PensionFormData,
  PersonalFormData,
} from "types";

export default function PersonalFormDialog(props: {
  locale: "nl" | "fr";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resultData: OneManBusinessResults | DirectorResults;
  pensionFormData: PensionFormData;
  formText: FormText;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarVariant: Dispatch<SetStateAction<"success" | "error">>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const form = useForm<PersonalFormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: {
      termsOfAgreementAndContactExpert: false,
      // contactExpert: true,
    },
  });

  const handleDialogClose = () => {
    props.setOpen(false);
    // form.reset({}, { keepDefaultValues: true });
    form.resetField("termsOfAgreementAndContactExpert");
    //form.resetField("contactExpert");
  };

  const onSubmit: SubmitHandler<PersonalFormData> = async (data) => {
    setLoading(true);

    // ReactGA.event("", {
    //   event:"cta_click",
    //   component: "pension_simulator",
    //   event_label: props.formText.modalButtonSubmit,
    // });
    //@ts-ignore
    window.dataLayer.push({
      event: "cta_click",
      component: "pension_simulator",
      event_label: props.formText.modalButtonSubmit,
    });

    // if (props.pensionFormData.selfEmployedType === "director") {
    //   delete data["infoStartingCompany"]; //? this Simply removes the property from the object so it won't even reach the backend, however i'd recommend putting this check in the backend itself
    // }

    const formattedData = {
      pensionFormData: props.pensionFormData,
      personalFormData: data,
      resultsData: props.resultData,
      locale: props.locale,
    };
    try {
      const apiResponse = await axios.post(
        "/api/personalFormHandler",
        formattedData,
        {
          headers: { "x-api-key": "148A3FECE6785" },
        }
      );
      if (apiResponse.status === 200) {
        props.setSnackbarVariant("success");
        props.setSnackbarOpen(true);
      }
    } catch (error) {
      props.setSnackbarVariant("error");
      props.setSnackbarOpen(true);
    } finally {
      setLoading(false);
      handleDialogClose();
    }
  };

  return (
    <Dialog
      fullScreen={fullscreen}
      open={props.open}
      onClose={handleDialogClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper square sx={{ backgroundColor: "#F2F2F2" }}>
        <Container maxWidth="xs" sx={{ mt: 3 }}>
          <FormProvider {...form}>
            <form>
              <FormSpacer>
                <Box display={"flex"} flexDirection={"row"}>
                  <Typography>{props.formText.modalTitle}</Typography>
                  <IconButton onClick={handleDialogClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"left"}
                  flexDirection={"row"}
                  pb={0}
                >
                  {/* <Typography variant="body2">
                    {props.formText.requiredFieldsText}
                  </Typography> */}
                </Box>
                <Grid container rowSpacing={1.5} direction={"column"}>
                  <Grid item sm={12}>
                    <Label label={props.formText.email} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="email"
                      rules={{
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                          message: `${props.formText.wrongEmail}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.firstName} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="firstName"
                      rules={{
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[\s\S]{1,200}$/u,
                          message: `${props.formText.notAbove} 200 ${props.formText.characters}.`,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Label label={props.formText.lastName} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="lastName"
                      rules={{
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[\s\S]{1,200}$/,
                          message: `${props.formText.notAbove} 200 ${props.formText.characters}.`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.companyName} />
                    <TextFieldInput
                      name="companyName"
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.phoneNumber} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="phone"
                      rules={{
                        required: `${props.formText.required}`,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Label label={props.formText.zipCode} />
                    <TextFieldInput
                      TextFieldProps={{ inputProps: { readOnly: loading } }}
                      name="zipCode"
                      rules={{
                        required: `${props.formText.required}`,
                        pattern: {
                          value: /^[\d]{4}$/,
                          message: `${props.formText.wrongZipCode}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormSpacer removePadding>
                      <CheckBoxInput
                        name="termsOfAgreementAndContactExpert"
                        muiCheckboxProps={{ inputProps: { readOnly: loading } }}
                        disabled={loading}
                        defaultValue={false}
                        label={
                          <Typography
                            fontSize={"0.9rem"}
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              padding: "8px 0 0",
                            }}
                          >
                            <div>
                              {
                                props.formText
                                  .modalCombinedPrivacyContactExpertText
                              }
                              <MuiLink
                                target="_blank"
                                rel="noopener"
                                href={
                                  props.locale === "fr"
                                    ? "https://www.pv.be/fr/privacy"
                                    : "https://www.pv.be/nl/privacy"
                                }
                                color="secondary"
                              >
                                {props.formText.modalPrivacyTextLinkText}
                              </MuiLink>
                              ).
                            </div>
                          </Typography>
                        }
                      />
                      {/* <CheckBoxInput
                        disabled={loading}
                        muiCheckboxProps={{
                          inputProps: { readOnly: loading },
                        }}
                        name="contactExpert"
                        defaultValue={true}
                        label={<div>{props.formText.modalContactExpert}</div>}
                      /> */}
                    </FormSpacer>
                  </Grid>
                </Grid>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  loading={loading}
                  loadingPosition="start"
                  loadingIndicator={<LoadingSpinner locale={props.locale} />}
                  onClick={form.handleSubmit(onSubmit)}
                  sx={{ p: 2, border: "1px solid #870930" }}
                >
                  <Typography
                    fontSize={props.locale === "nl" ? "1rem" : "0.95rem"}
                    color={"primary"}
                  >
                    {loading
                      ? props.formText.modalButtonLoadingText
                      : props.formText.modalButtonSubmit}
                  </Typography>
                </LoadingButton>
              </FormSpacer>
            </form>
          </FormProvider>
        </Container>
      </Paper>
    </Dialog>
  );
}
