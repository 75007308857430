import PensionTool from "@client/pages/pensionTool/Index";
import { Box } from "@mui/material";
import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const locale = useRouter().locale;
  const router = useRouter();

  // fix for query parameters
  if (router.query && Object.keys(router.query).length !== 0) {
    router.push("/");
  }

  // fix for path
  if (router.pathname !== "/") {
    router.push("/");
  }

  return (
    <>
      <Head>
        {locale == "nl" && (
          <meta property="og:title" content="Pensioen simulator" />
        )}
        {locale == "nl" && (
          <meta property="og:url" content="https://pensiontool.pv.be" />
        )}
        {locale == "fr" && (
          <meta property="og:title" content="Simulateur de pension" />
        )}
        {locale == "fr" && (
          <meta property="og:url" content="https://pensiontool.pv.be/fr" />
        )}
        <title>pensioenoptimalisatie</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <PensionTool />
      </Box>
      <div>
        <div className="CookieDeclaration" hidden={true} />
      </div>
    </>
  );
};

export default Home;
