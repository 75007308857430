import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormText, MainText } from "@public/cms/types";
import ReactGA from "react-ga4";
import { theme } from "@styles/Theme";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import datalabels from "chartjs-plugin-datalabels";
import { Dispatch, SetStateAction, useState } from "react";
import { Bar } from "react-chartjs-2";
import { DirectorResults, OneManBusinessResults, PensionFormData } from "types";
import PersonalFormModal from "./PersonalFormDialog";

export default function PensionResult(props: {
  locale: "nl" | "fr";
  resultData: OneManBusinessResults | DirectorResults;
  pensionFormData: PensionFormData;
  mainText: MainText;
  formText: FormText;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarVariant: Dispatch<SetStateAction<"success" | "error">>;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    datalabels
  );

  const graphData = {
    labels: [`${props.mainText.inkomen}`, `${props.mainText.legalPension}`],
    datasets: [
      {
        label: "Bruto-inkomen",
        // backgroundColor: "#FFFFFF",
        // borderColor: "#870930",
        backgroundColor: "#870930",
        data: [
          props.resultData.monthlyIncome.currentIncome,
          props.resultData.monthlyIncome.legalPension,
        ],
        barPercentage: 0.7,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: { size: fullscreen ? 12 : 18 },
        },
      },
      y: {
        ticks: {
          // Include a euro sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return props.locale == "nl"
              ? "€ " +
                  value.toLocaleString("fr-FR", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
              : value.toLocaleString("fr-FR", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) + " €";
          },
          font: { size: fullscreen ? 12 : 18 },
        },
      },
    },
    // elements: {
    //   bar: {
    //     borderWidth: 2,
    //   },
    // },
    plugins: {
      datalabels: {
        formatter: function (value: number, context: any) {
          return props.locale == "nl"
            ? "€ " +
                value
                  .toLocaleString("nl-NL", {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })
                  .replace(/[\.]+/g, " ")
            : value
                .toLocaleString("nl-NL", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
                .replace(/[\.]+/g, " ") + " €";
        },
        // color: "#870930",
        color: "#FFFFFF",
        font: {
          weight: "bold" as "bold",
          size: fullscreen ? 12 : 18,
        },
      },
    },
    events: [],
  };

  const legalPensionGap =
    " -  " +
    Math.abs(
      props.resultData.monthlyIncome.currentIncome -
        props.resultData.monthlyIncome.legalPension
    ).toLocaleString("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  const onClick = async () => {
    // ReactGA.event("cta_click", {
    //   event:"cta_click",
    //   component: "pension_simulator",
    //   event_label: props.mainText.resultScreenButtonSubmit,
    // });
    //@ts-ignore
    window.dataLayer.push({
      event: "cta_click",
      component: "pension_simulator",
      event_label: props.mainText.resultScreenButtonSubmit,
    });
    setOpenModal(true);
  };

  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: 8 }}>
        <Paper
          square
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Typography
            color={"primary"}
            fontWeight={"bold"}
            textAlign={"center"}
            variant="h3"
          >
            {props.mainText.resultScreenTitle}
          </Typography>
          <Typography color={"grey"} variant="h5" fontWeight={600}>
            {props.mainText.resultScreenSubtitle}
          </Typography>

          <Box marginTop={5} marginBottom={5} height={"100%"} width={"100%"}>
            <Bar options={options} data={graphData} />
          </Box>
          <Divider component={"hr"} flexItem />
          <Box mb={2}>
            <Typography
              color={"gray"}
              variant="h5"
              fontWeight={600}
              mt={2}
              mb={2}
            >
              {props.mainText.pensionGapSubtitle}
            </Typography>
            <Typography color={"primary"} variant="h2" fontWeight={500}>
              {props.locale == "nl"
                ? "€ " + legalPensionGap
                : legalPensionGap + " €"}
            </Typography>
            <Typography>{props.mainText.pensionGapBefore}</Typography>
            <Typography variant="body2">
              {props.mainText.inflationText}
            </Typography>
          </Box>
          <Divider component={"hr"} flexItem />
          <Box
            maxWidth={"sm"}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            <Typography>{props.mainText.resultScreenBodyOne}</Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            // sx={{ background:"#ff6600" }}
            onClick={onClick}
          >
            {props.mainText.resultScreenButtonSubmit}
          </Button>
        </Paper>
      </Container>
      <PersonalFormModal
        locale={props.locale}
        open={openModal}
        setOpen={setOpenModal}
        resultData={props.resultData}
        pensionFormData={props.pensionFormData}
        formText={props.formText}
        setSnackbarOpen={props.setSnackbarOpen}
        setSnackbarVariant={props.setSnackbarVariant}
      />
    </>
  );
}
