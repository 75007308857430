import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@mui/material";
import React, { ReactElement } from "react";
import ReactGA from "react-ga4";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

export default function CheckboxGroup(props: {
  name: string;
  defaultValue: boolean;
  disabled?: boolean;
  label: ReactElement;
  rules?: RegisterOptions;
  muiFormControlLabelProps?: Omit<
    MuiFormControlLabelProps,
    "control" | "label"
  >;
  muiCheckboxProps?: MuiCheckboxProps;
}) {
  const [checked, setChecked] = React.useState(props.defaultValue);

  const form = useFormContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={form.control}
      name={props.name}
      rules={props.rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl error={fieldState.error ? true : false}>
            <FormControlLabel
              label={props.label}
              {...props.muiFormControlLabelProps}
              control={
                <MuiCheckbox
                  size="small"
                  checked={checked}
                  value={checked}
                  disabled={formState.isSubmitting}
                  {...props.muiCheckboxProps}
                  sx={{ alignSelf: "flex-start" }}
                  onChange={(event) => {
                    if (props.disabled) {
                      return;
                    }
                    // ReactGA.event("checkbox_click", {
                    //   event: "checkbox_click",
                    //   component: "pension_simulator",
                    //   event_label: "consent",
                    // });
                    //@ts-ignore
                    window.dataLayer.push({
                      event: "cta_click",
                      component: "pension_simulator",
                      event_label: "consent",
                    });
                    handleChange(event);
                    return field.onChange(event.target.checked);
                  }}
                />
              }
            />
            {fieldState.error && (
              <FormHelperText
                error
                sx={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    {/* <ReportProblemRoundedIcon sx={{ mr: 1 }} fontSize="small" /> */}
                    {fieldState.error?.message}
                  </>
                }
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
}
