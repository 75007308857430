import { Box, Container, Typography } from "@mui/material";
import footerJson from "@public/cms/footer.json";
import { FooterText } from "@public/cms/types";
import selectText from "helpers/selectText";
import { useRouter } from "next/router";

export default function Attention() {
  const router = useRouter();
  const language = router.locale;
  const footerText = selectText(
    footerJson,
    language as "nl" | "fr"
  ) as FooterText;

  return (
    <>
      <Box sx={{ borderTop: 1, mb: 1.5 }}>
        <Container maxWidth={"lg"} sx={{ paddingTop: 3, paddingBottom: 3 }}>
          <Typography variant="h5" color={"primary"} sx={{ mb: 2 }}>
            {footerText.disclaimerTitle}
          </Typography>
          <Typography variant="body1" textAlign={"justify"}>
            {footerText.disclaimerTextBeforeLink}
          </Typography>
        </Container>
      </Box>
    </>
  );
}
