import React from "react";
import { useTheme } from "@mui/material/styles";
import { Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export type LabelProps = {
  label: string;
  tooltip?: string | React.ReactNode;
  tooltipIcon?: JSX.Element;
};

export default function Label(props: LabelProps) {
  const theme = useTheme();
  const tooltipIcon = props.tooltipIcon ?? (
    <InfoIcon htmlColor={"rgba(135, 9, 48, 1)"} sx={{ fontSize: 20 }} />
  );
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Typography
        variant="subtitle1"
        color={"#4d4d4d"}
        style={{
          marginBottom: theme.spacing(0.3),
          marginRight: theme.spacing(1),
          // textTransform: "uppercase",
        }}
      >
        {props.label}
      </Typography>
      {props.tooltip && (
        <Tooltip
          sx={{ fontSize: 14 ,marginBottom: theme.spacing(0.4) }}
          placement="right"
          title={props.tooltip}
        >
          {tooltipIcon}
        </Tooltip>
      )}
    </div>
  );
}
