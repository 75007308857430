import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function LoadingSpinner(props: { locale: "nl" | "fr" }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        sx={{ color: "#870930" }}
        size={props.locale === "nl" ? 20 : 15}
      />
    </Box>
  );
}
