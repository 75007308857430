import { Container, Stack, Typography } from "@mui/material";
import { MainText } from "@public/cms/types";

export default function Header(props: { mainText: MainText }) {
  return (
    <>
      <Container maxWidth="sm" sx={{ marginTop: 8}}>
        <Stack spacing={2}>
          <Typography variant="h2" fontWeight={700} color={"primary"} textAlign={"justify"}>
            {props.mainText.firstScreentitle}
          </Typography>
          <Typography variant="h5" fontWeight={600} color={"primary"} textAlign={"justify"}>
            {props.mainText.firstScreenSubtitle}
          </Typography>
          <Typography color={"grey"} textAlign={"justify"}>
            {props.mainText.firstScreenSubtitleText}
          </Typography>
        </Stack>
      </Container>
    </>
  );
}
