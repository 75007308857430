import { Box, Button, Container } from "@mui/material";
import { sizes, theme } from "@styles/Theme";
import { useState } from "react";
import PensionForm from "./PensionForm";
import PensionHeader from "./PensionHeader";
import PensionResult from "./PensionResult";
import CustomSnackbar from "@client/components/CustomSnackbar";
import { DirectorResults, OneManBusinessResults, PensionFormData } from "types";
import main from "@public/cms/main.json";
import form from "@public/cms/form.json";
import selectText from "helpers/selectText";
import { MainText, FormText } from "@public/cms/types";
import { useRouter } from "next/router";
import Attention from "@client/components/Attention";

export default function PensionTool() {
  const [pageState, setPageState] = useState<"form" | "result">("form");
  const [apiResponse, setApiResponse] = useState<
    OneManBusinessResults | DirectorResults | undefined
  >(undefined);
  const [pensionFormData, setPensionFormData] = useState<
    PensionFormData | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<"error" | "success">(
    "error"
  );

  const router = useRouter();
  const language = (router.locale ?? "nl") as "nl" | "fr";
  const mainText = selectText(main, language as "nl" | "fr") as MainText;
  const formText = selectText(form, language as "nl" | "fr") as FormText;

  const handleBack = () => {
    setPageState("form");
  };
  return (
    <>
      <Box mt={sizes.navBarHeight} mb={10}>
        {pageState == "form" && (
          <>
            <PensionHeader mainText={mainText} />
            <PensionForm
              loading={loading}
              setApiResponse={setApiResponse}
              setLoading={setLoading}
              setSnackbarOpen={setSnackbarOpen}
              setPageState={setPageState}
              setSnackbarVariant={setSnackbarVariant}
              setPensionFormData={setPensionFormData}
              mainText={mainText}
              formText={formText}
            />
          </>
        )}

        {pageState == "result" && apiResponse && pensionFormData && (
          <>
            <PensionResult
              locale={language}
              resultData={apiResponse}
              pensionFormData={pensionFormData}
              mainText={mainText}
              formText={formText}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarVariant={setSnackbarVariant}
            />
            <Container sx={{ mt: 2 }} maxWidth={"md"}>
              {/* <Button
                fontSize={"18px"}
                onClick={() => setPageState("form")}
                // sx={{
                //   textUnderlineOffset: 4,
                //   cursor: "pointer",
                //   color: "secondary.light",
                // }}
              > */}
              <Button
                onClick={handleBack}
                sx={{
                  textUnderlineOffset: 4,
                  cursor: "pointer",
                  color: "secondary.light",
                  borderBottom: `1px solid ${theme.palette.secondary.light}`,
                  p: 0,
                  height: `max-content`,
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {mainText.goBack}
              </Button>
            </Container>
          </>
        )}
      </Box>
      <CustomSnackbar
        locale={language}
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        variant={snackbarVariant}
      />
      <Attention />
    </>
  );
}
